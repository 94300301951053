import React from 'react';
import './App.css';
import AppRouter from "./component/RouterComponent";

function App() {
    return (

        <AppRouter/>
    );
}

export default App;
