import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import Zoom  from '@material-ui/core/Zoom';

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const style = {
    flexGrow: 1
}
const NavBar = () => {
const [anchor, setAnchor] = useState(null);
  const options = ["Home", "Update Password"];
  const [selected, setSelected] = useState(-1);

  const openMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const onMenuItemClick = (event, index) => {
    setAnchor(null);
    setSelected(index);
    console.log("selected index: "+index);
    if(index===0){
        window.location.href = "/list-user";
    } else if(index===1){
        window.location.href = "/update-password";
    }
  };

    return (
            <div>
                <AppBar position="static">
                    <Toolbar>
                    <IconButton aria-label="Menu" align="left">
                           <Box m={1}>
                            <MenuIcon onClick={openMenu}  color="inherit" variant="contained" />
                                 <Menu
                                   open={Boolean(anchor)}
                                   anchorEl={anchor}
                                   onClose={closeMenu}
                                   keepMounted
                                   TransitionComponent={Zoom}
                                   PaperProps={{
                                     style: {
                                       maxHeight: 40 * 4,
                                       width: "20ch",
                                     },
                                   }}
                                 >
                                   {options.map((option, index) => (
                                     <MenuItem
                                       key={index}
                                       onClick={(event) => onMenuItemClick(event, index)}
                                       selected={index === selected}
                                     >
                                       {option}
                                     </MenuItem>
                                   ))}
                                 </Menu>
                               </Box>
                    </IconButton>
                    <a href="/list-user" target="_self" rel="noreferrer">
                          <img src={require('../images/jamaathApp-logo-2-Horizontal.PNG')} alt="logo" style={styles.logo} component={Link}  to="/list-user"/>
                    </a>
                    <Grid container justify="space-between">
                         <Typography  sx={{ minWidth: 80 }}
                                               color="inherit"
                                               noWrap
                                               style={{ marginLeft: 5 }}
                                               align="right">
                           {JSON.parse(window.localStorage.getItem("userInfo")).username.substring(0, 22)}
                        </Typography>
                        <Typography  sx={{ minWidth: 30 }}
                                               color="inherit"
                                               noWrap
                                               style={{ marginRight: 1 }}
                                               style={{ marginLeft: 5 }}
                                               align="right">
                         <a href="/" target="_self" rel="noreferrer">
                              <img src={require('../images/logout.png')} alt="logout"
                                 style={styles.logout} />
                         </a>
                         </Typography>
                    </Grid>
                    </Toolbar>
                </AppBar>
            </div>
        )
}


const styles= {
    logo: {
           maxWidth: 80,
           height: 25
         },
      logout: {
        maxWidth: 30,
        height: 30
      }
}

export default NavBar;
