import React, { Component } from 'react'
import UserService from "../../service/UserService";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import NavBar from "../Navbar";

class UpdatePasswordComponent extends Component {

    constructor(props){
        super(props);
        this.state ={
                id: '',
                username: '',
                newPassword: '',
                verifyPassword: '',
        }
        this.updateUserPassword = this.updateUserPassword.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.listUser = this.listUser.bind(this);
    }

    componentDidMount() {
        this.loadUser();
    }

    loadUser() {
        UserService.fetchUserById(window.localStorage.getItem("userId"))
            .then((res) => {
                let user = res.data.result;
                this.setState({
                    id: user.id,
                    username: user.username,
                    newPassword: user.newPassword,
                    verifyPassword: user.verifyPassword,
                })
            });
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    updateUserPassword = (e) => {
        e.preventDefault();
        var lowerCase = /[a-z]/g;
        var upperCase = /[A-Z]/g;
        var numbers = /[0-9]/g;
        if(this.state.newPassword !== this.state.verifyPassword){
             this.setState({message: 'newPassword verifyPassword does not match'});
             return;
         } else if(this.state.newPassword.length < 6){
            this.setState({message: 'Password rules does not match - size'});
            return;
         } else if(this.state.newPassword.length < 6){
           this.setState({message: 'Password rules does not match - length'});
           return;
         } else if(!this.state.newPassword.match(lowerCase)){
            this.setState({message: 'Password rules does not match - lowerCase'});
            return;
         } else if(!this.state.newPassword.match(upperCase)){
           this.setState({message: 'Password rules does not match - upperCase'});
           return;
        } else if(!this.state.newPassword.match(numbers)){
           this.setState({message: 'Password rules does not match - numbers'});
           return;
        }

        let user = {
                id: this.state.id, 
                username: this.state.username,
                password: this.state.newPassword
                };
        UserService.updateUserPassword(user)
            .then(res => {
                this.setState({message : 'Password Updated successfully.'});
                this.props.history.push('/update-password');
            });
    }
    
    listUser = (e) => {
        e.preventDefault();
        this.props.history.push('/list-user');
    }

    render() {
        return (
            <React.Fragment>
                <NavBar/>
                <Container>
                    <Typography variant="h4" style={styles.title}>Update Password</Typography>
                    <form>
                        <Typography variant="h6" style={styles.notification}>{this.state.message}</Typography>

                           <li>Minimum length of the password is 6 characters.</li>
                           <li>Password should contain an uppercase and a lowercase letter.</li>
                           <li>Password should contain a numbers.</li>

                        <TextField placeholder="new Password" fullWidth margin="normal" name="newPassword" value={this.state.newPassword} onChange={this.onChange}/>
                        <TextField placeholder="verify Password" fullWidth margin="normal" name="verifyPassword" value={this.state.verifyPassword} onChange={this.onChange}/>

                        <Grid container spacing={8}>
                            <Grid item xs={4}>
                                     <Button variant="contained" color="primary" onClick={this.updateUserPassword}>Update</Button>
                            </Grid>
                            <Grid item xs={4}>
                                    <Button variant="contained" color="secondary" onClick={this.listUser} >Cancel</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            </React.Fragment>
        );
    }
}


const styles ={
    title: {
       display: 'flex',
       justifyContent: 'center'
    },
    notification: {
        display: 'flex',
        justifyContent: 'center',
        color: '#0E7A1D'
    },
}

export default UpdatePasswordComponent;