import React, { useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import Map from "./Map";
import "./styles.css";

export default function MapContainer() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDaBbO70tz6TPZHFKrsmwPHsGahglVeXVs" // Add your API key
  });

  return isLoaded ? <Map /> : null;
}
