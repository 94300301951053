import React, { Component } from 'react'
import UserService from "../../service/UserService";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import NavBar from "../Navbar";

class EditUserComponent extends Component {

    constructor(props){
        super(props);
        this.state ={
                id: '',
                role: '',
                firstName: '',
                lastName: '',
                masjidName: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                zipCode: '',
                state: '',
                phone1: '', 
                timeSpend: '', 
                comments: '',     
                profession: '',
                dateLastMet: '',
                displayChangeRoleButton: false,
        }
        this.saveUser = this.saveUser.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.listUser = this.listUser.bind(this);
        this.changeRole = this.changeRole.bind(this);
    }

    componentDidMount() {
        this.loadUser();
    }

    loadUser() {
       let currentUserRole = JSON.parse(window.localStorage.getItem("userInfo")).role;
        console.log('initial currentUserRole : '+currentUserRole)
        UserService.fetchUserById(window.localStorage.getItem("userId"))
            .then((res) => {
                let user = res.data.result;
                this.setState({
                    id: user.id,
                    role: user.role,
                    displayChangeRoleButton: currentUserRole==='SUPER_ADMIN' || currentUserRole==='COUNTRY_ADMIN' ? true : false,
                    username: user.username,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    masjidName: user.masjidName,
                    addressLine1: user.addressLine1,
                    addressLine2: user.addressLine2,
                    city: user.city,
                    zipCode: user.zipCode,
                    state: user.state,
                    phone1: user.phone1,
                    timeSpend: user.timeSpend,
                    comments: user.comments,
                    profession: user.profession,
                    dateLastMet: user.dateLastMet,
                })
            });
            console.log('currentUserRole : '+currentUserRole)
            console.log('displayChangeRoleButton : '+this.state.displayChangeRoleButton)
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    saveUser = (e) => {
        e.preventDefault();
        let user = {
                id: this.state.id, 
                password: this.state.password,
                firstName: this.state.firstName, 
                lastName: this.state.lastName,
                masjidName: this.state.masjidName,
                addressLine1: this.state.addressLine1,
                addressLine2: this.state.addressLine2,
                city: this.state.city,
                zipCode: this.state.zipCode,
                state: this.state.state,
                phone1: this.state.phone1,
                timeSpend: this.state.timeSpend,
                comments: this.state.comments,
                profession: this.state.profession,
                dateLastMet: this.state.dateLastMet
                };
        UserService.editUser(user)
            .then(res => {
                this.setState({message : 'User added successfully.'});
                this.props.history.push('/list-user');
            });
    }

    changeRole = (e) => {
        e.preventDefault();
        this.props.history.push('/update-role');
    }

    
    listUser = (e) => {
        e.preventDefault();
        this.props.history.push('/list-user');
    }

    render() {
        return (
            <React.Fragment>
                <NavBar/>
                <Container>
                    <Typography variant="h4" style={style}>Edit User</Typography>
                    <form>

                    <TextField type="text" placeholder="username" fullWidth margin="normal" name="username" readonly="true" value={this.state.username}/>
                    <TextField placeholder="First Name" fullWidth margin="normal" name="firstName" value={this.state.firstName} onChange={this.onChange}/>
                    <TextField placeholder="Last Name" fullWidth margin="normal" name="lastName" value={this.state.lastName} onChange={this.onChange}/>
                    <TextField placeholder="Masjid Name" fullWidth margin="normal" name="masjidName" value={this.state.masjidName} onChange={this.onChange}/>
                    <TextField placeholder="Profession" fullWidth margin="normal" name="profession" value={this.state.profession} onChange={this.onChange}/>
                    <TextField placeholder="AddressLine1" fullWidth margin="normal" name="addressLine1" value={this.state.addressLine1} onChange={this.onChange}/>
                    <TextField placeholder="AddressLine2" fullWidth margin="normal" name="addressLine2" value={this.state.addressLine2} onChange={this.onChange}/>
                    <TextField placeholder="City" fullWidth margin="normal" name="city" value={this.state.city} onChange={this.onChange}/>
                    <TextField placeholder="ZipCode" fullWidth margin="normal" name="zipCode" value={this.state.zipCode} onChange={this.onChange}/>
                    <TextField placeholder="State" fullWidth margin="normal" name="state" value={this.state.state} onChange={this.onChange}/>
                    <TextField placeholder="Phone1" fullWidth margin="normal" name="phone1" value={this.state.phone1} onChange={this.onChange}/>
                    <TextField placeholder="TimeSpend" fullWidth margin="normal" name="timeSpend" value={this.state.timeSpend} onChange={this.onChange}/>
                    <TextField placeholder="Comments" fullWidth margin="normal" name="comments" value={this.state.comments} onChange={this.onChange}/>
                    
                    <TextField disabled="true" placeholder="DateLastMet" fullWidth margin="normal" name="dateLastMet" value={this.state.dateLastMet} onChange={this.onChange}/>

                   {/*  <TextField type="number" placeholder="age" fullWidth margin="normal" name="age" value={this.state.age} onChange={this.onChange}/>

                    <TextField type="number" placeholder="salary" fullWidth margin="normal" name="salary" value={this.state.salary} onChange={this.onChange}/> */}
                    
                    <Grid container spacing={12}>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" onClick={this.saveUser}>Save</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="secondary" onClick={this.listUser} >Cancel</Button>
                    </Grid>


                     <Grid item xs={4}>
                     {this.state.displayChangeRoleButton===true ?
                         <Button variant="contained" color="primary" onClick={this.changeRole} >Change_Role</Button>
                     : null }
                     </Grid>



               </Grid>
                       
                       
                    </form>
                </Container>
            </React.Fragment>
        );
    }
}

const style ={
    display: 'flex',
    justifyContent: 'center'
}

export default EditUserComponent;