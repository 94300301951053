import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ListUserComponent from "./user/ListUserComponent";
import AddUserComponent from "./user/AddUserComponent";
import EditUserComponent from "./user/EditUserComponent";
import UpdateRoleComponent from "./user/UpdateRoleComponent";
import React from "react";
import LoginComponent from "./user/LoginComponent";
import UpdatePasswordComponent from "./user/UpdatePasswordComponent";
import UpdateUserModal from "./user/UpdateUserModal";

const AppRouter = () => {
    return(
            <Router>
                    <Switch>
                        <Route path="/" exact component={LoginComponent} />
                        <Route path="/list-user" component={ListUserComponent} />
                        <Route path="/add-user" component={AddUserComponent} />
                        {/* <Route path="/edit-user" component={UpdateUserModal} /> */}
                        <Route path="/edit-user" component={EditUserComponent} />
                       <Route path="/update-role" component={UpdateRoleComponent} />
                        <Route path="/search-listing" component={ListUserComponent} />
                        <Route path="/update-password" component={UpdatePasswordComponent} />
                    </Switch>
            </Router>
    )
}

export default AppRouter;