import React, { Component, Fragment } from 'react'
import Grid from '@material-ui/core/Grid';
import UserService from "../../service/UserService";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import NavBar from "../Navbar";

class AddUserComponent extends Component{

    constructor(props){
        super(props);
        this.state ={
                username: '',
                password: '',
                firstName: '',
                lastName: '',
                masjidName: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                zipCode: '',
                state: '',
                phone1: '',       
                timeSpend: '',     
                comments: '',     
                profession: '',     
                dateLastMet: '',           
                message: null
        }
        this.saveUser = this.saveUser.bind(this);
        this.listUser = this.listUser.bind(this);
    }

    saveUser = (e) => {
        e.preventDefault();
        let user = {
                username: this.state.username, 
                password: this.state.password, 
                firstName: this.state.firstName, 
                lastName: this.state.lastName,
                masjidName: this.state.masjidName,
                addressLine1: this.state.addressLine1,
                addressLine2: this.state.addressLine2,
                city: this.state.city,
                zipCode: this.state.zipCode,
                state: this.state.state,
                phone1: this.state.phone1,
                timeSpend: this.state.timeSpend,
                comments: this.state.comments,
                profession: this.state.profession,
                dateLastMet: this.state.dateLastMet
                };
        UserService.addUser(user)
            .then(res => {
                this.setState({message : 'User added successfully.'});
                this.props.history.push('/list-user');
            });
    }
    
    listUser = (e) => {
        e.preventDefault();
        this.props.history.push('/list-user');
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    render() {
        return(
            <Fragment>
                <NavBar/>
                <Container>
                    <Typography variant="h4" style={style}>Add User</Typography>
                    <form style={formContainer}>

                    {/*  <TextField type="text" placeholder="username" fullWidth margin="normal" name="username" value={this.state.username} onChange={this.onChange}/>

                    <TextField type="password" placeholder="password" fullWidth margin="normal" name="password" value={this.state.password} onChange={this.onChange}/> */}

                    <TextField placeholder="First Name" fullWidth margin="normal" name="firstName" value={this.state.firstName} onChange={this.onChange}/>
                    <TextField placeholder="Last name" fullWidth margin="normal" name="lastName" value={this.state.lastName} onChange={this.onChange}/>
                    <TextField placeholder="Masjid Name" fullWidth margin="normal" name="masjidName" value={this.state.masjidName} onChange={this.onChange}/>
                    <TextField placeholder="Profession" fullWidth margin="normal" name="profession" value={this.state.profession} onChange={this.onChange}/>
                    <TextField placeholder="AddressLine1" fullWidth margin="normal" name="addressLine1" value={this.state.addressLine1} onChange={this.onChange}/>
                    <TextField placeholder="AddressLine2" fullWidth margin="normal" name="addressLine2" value={this.state.addressLine2} onChange={this.onChange}/>
                    <TextField placeholder="City" fullWidth margin="normal" name="city" value={this.state.city} onChange={this.onChange}/>
                    <TextField placeholder="ZipCode" fullWidth margin="normal" name="zipCode" value={this.state.zipCode} onChange={this.onChange}/>
                    <TextField placeholder="State" fullWidth margin="normal" name="state" value={this.state.state} onChange={this.onChange}/>
                    <TextField placeholder="Phone1" fullWidth margin="normal" name="phone1" value={this.state.phone1} onChange={this.onChange}/>

                    <TextField placeholder="TimeSpend" fullWidth margin="normal" name="timeSpend" value={this.state.timeSpend} onChange={this.onChange}/>
                    <TextField placeholder="Comments" fullWidth margin="normal" name="comments" value={this.state.comments} onChange={this.onChange}/>
                   
                    <TextField placeholder="DateLastMet" fullWidth margin="normal" name="dateLastMet" value={this.state.dateLastMet} onChange={this.onChange}/>

                   {/*  <TextField type="number" placeholder="age" fullWidth margin="normal" name="age" value={this.state.age} onChange={this.onChange}/>

                    <TextField type="number" placeholder="salary" fullWidth margin="normal" name="salary" value={this.state.salary} onChange={this.onChange}/>*/}
                    
                    <Grid container spacing={8}>
                        <Grid item xs={4}>
                           <Button variant="contained" color="primary" onClick={this.saveUser}>Save</Button>
                        </Grid>
                        <Grid item xs={4}>
                           <Button variant="contained" color="secondary" onClick={this.listUser}>Cancel</Button>
                        </Grid>
                   </Grid>
                      
                      
                    </form>
                </Container>
            </Fragment>
        );
    }
}
const formContainer = {
    display: 'flex',
    flexFlow: 'row wrap'
};

const style ={
    display: 'flex',
    justifyContent: 'center'

}

export default AddUserComponent;