import React, { Component } from 'react'
import UserService from "../../service/UserService";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import NavBar from "../Navbar";


class UpdateRoleComponent extends Component {

    constructor(props){
        super(props);
        this.state ={
                id: '',
                email: '',
                username: '',
                role: '',
                firstName: '',
                lastName: '',
                masjidName: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                zipCode: '',
                state: '',
                phone1: '', 
                timeSpend: '', 
                comments: '',     
                profession: '',
                dateLastMet: '', 
        }       
        this.loadUser = this.loadUser.bind(this);
        this.listUser = this.listUser.bind(this);
        this.updateRole = this.updateRole.bind(this);        
    }

    componentDidMount() {
        this.loadUser();
    }

    loadUser() {
        UserService.fetchUserById(window.localStorage.getItem("userId"))
            .then((res) => {
                let user = res.data.result;
                if( user.email == null){
                    user.email = user.username
                }
                this.setState({
                    id: user.id,
                    email: user.email,
                    role: user.role,
                    username: user.username,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    masjidName: user.masjidName,
                    addressLine1: user.addressLine1,
                    addressLine2: user.addressLine2,
                    city: user.city,
                    zipCode: user.zipCode,
                    state: user.state,
                    phone1: user.phone1,
                    timeSpend: user.timeSpend,
                    comments: user.comments,
                    profession: user.profession,
                    dateLastMet: user.dateLastMet,
                })
            });
            console.log(this.state.email !== null || this.state.email !== ''? true : false);
    }

    onChange = (e) =>
        this.setState({ [e.target.name]: e.target.value });

    updateRole = (e) => {
        e.preventDefault();
        let user = {
                id: this.state.id, 
                email: this.state.email,
                role: this.state.role,
                masjidName: this.state.masjidName,
                city: this.state.city,
                zipCode: this.state.zipCode,
                state: this.state.state
                };
                UserService.updateUserRole(user)
                    .then(res => {                      
                        this.setState({message : JSON.stringify(res.data.message)});
                        this.props.history.push('/update-role');
                    });
    }
    
    listUser = (e) => {
        e.preventDefault();
        this.props.history.push('/list-user');
    }

    render() {
        return (
            <React.Fragment>
                <NavBar/>
                <Container>
                    <Typography variant="h4" style={styles.title}>Update User Role</Typography>
                    <form>
                    <Typography variant="h6" style={styles.notification}>{this.state.message}</Typography>
                    <hr class="separator" />
                                    <Grid container>                                  
                                            <Grid item sm={12} > 
                                               <Typography variant="caption" display="block" gutterBottom style={{color: '#360392' , fontSize: '12px' , textDecorationLine: 'underline', fontWeight: 'bold' , fontStyle: 'italic'}}>
                                                   <a> {this.state.id}. {this.state.firstName} {this.state.lastName} </a>
                                               </Typography>
                                               <Typography variant="caption" display="block" gutterBottom style={{color: '#008b02' , fontSize: '10px'}} >
                                                    {this.state.masjidName!==''  ? ' '+this.state.masjidName+'' : ' ' } {this.state.profession !== '' ? ' ('+this.state.profession+')' : null }
                                               </Typography>
                                               <Typography variant="body2" display="block" gutterBottom style={{fontSize: '10px'}}>                                                   
                                                   {this.state.addressLine1}, {this.state.addressLine2 != '' && this.state.addressLine2 != null ? ' '+this.state.addressLine2+',' : '' }  {this.state.city}, {this.state.zipCode}, {this.state.state}                                                   
                                               </Typography>                                            
                                               <Typography variant="body2" display="block" gutterBottom style={{fontSize: '10px'}}>
                                                 <span style={{color: '#000080'}}><a href={this.state.phone1 != '' && this.state.phone1 != null ? 'tel:'+this.state.phone1 : null }>{this.state.phone1 != '' && this.state.phone1 != null ? 'ph:'+this.state.phone1 : null }</a> </span>
                                                 <span style={{color: '#800080'}}>{this.state.timeSpend != '' && this.state.timeSpend != null ? ' ('+this.state.timeSpend+')' : null } </span>
                                               </Typography>  
                                             </Grid>
                                        </Grid>
                    <TextField placeholder="email" fullWidth margin="normal" name="email"  value={this.state.email} onChange={this.onChange}/>
                    <TextField placeholder="role"  fullWidth margin="normal" name="role"  value={this.state.role}  onChange={this.onChange}/>
                   
                    
                    <Grid container spacing={12}>
                        <Grid item xs={4}>
                        <Button variant="contained" color="primary" onClick={this.updateRole}>Save</Button>
                        </Grid>
                        <Grid item xs={4}>
                        <Button variant="contained" color="secondary" onClick={this.listUser} >Cancel</Button>
                        </Grid>
                    </Grid>
                       
                       
                    </form>
                </Container>
            </React.Fragment>
        );
    }
}


const styles ={
    title: {
       display: 'flex',
       justifyContent: 'center'
    },
    notification: {
        display: 'flex',
        justifyContent: 'center',
        color: '#0E7A1D'
    },
    separator: {
        height: '10px',
        backgroundColor: 'red',
        border: 'none',
      },
}

export default UpdateRoleComponent;