import { Component, createContext, useState } from 'react'
import * as React from 'react';
import UserService from "../../service/UserService";
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import NavBar from "../Navbar";
import { makeStyles } from "@material-ui/core/styles";
import SimpleModal from "./SimpleModal";
import GoogleMapsModal from "./GoogleMapsModal";
import UpdateUserModal from "./UpdateUserModal";

class ListUserComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            searchString: '',
            message: ''
        }

        this.deleteUser = this.deleteUser.bind(this);
        this.editUser = this.editUser.bind(this);
        this.addUser = this.addUser.bind(this);
        this.reloadUserList = this.reloadUserList.bind(this);
    }

    componentDidMount() {       
        this.reloadUserList();
    }
    
    reloadUserList() {
        UserService.fetchUsers()
            .then((res) => {
                this.setState({users: res.data.result});
                this.setUserListForMap(res.data.result);
            });
    }
    
    fetchUsersBySearchString() {
        let searchStringValue = this.state.searchString;       
        if (searchStringValue.trim() === "") {
            searchStringValue = "XXXXXXXXXX";
        }
        UserService.fetchUsersBySearchString(searchStringValue)
            .then((res) => {
                this.setState({users: res.data.result});
                this.setUserListForMap(res.data.result);
            });

    }

    setUserListForMap(users) {
              localStorage.removeItem("markers");
              localStorage.setItem('markers',  JSON.stringify(users));
    }

    deleteUser(userId) {
        UserService.deleteUser(userId)
           .then(res => {
               this.setState({message : 'User deleted successfully.'});
               this.setState({users: this.state.users.filter(user => user.id !== userId)});
           })
    }

    editUser(id) {
        window.localStorage.setItem("userId", id);
        this.props.history.push('/edit-user');
    }
    
    searchListing(id) {
        window.localStorage.setItem("userId", id);       
        this.fetchUsersBySearchString();
        this.props.history.push('/search-listing');
    }

    addUser() {
        window.localStorage.removeItem("userId");
        this.props.history.push('/add-user');
    }
    
    onChange = (e) =>
    this.setState({ [e.target.name]: e.target.value });

   
    
    render() {
        const useStyles = makeStyles(theme => ({
            root: {
              display: 'inline-block',
              borderRadius: '50%',
              flexGrow: 0,
              flexShrink: 0
            },
            sm: {
              height: 15,
              width: 15
            },
            md: {
              height: 15,
              width: 15
            },
            lg: {
              height: 15,
              width: 15
            },
            
            
            name: {
                fontWeight:'bold',
                color: theme.palettle.secondary.dark        
            },
            
            
            neutral: { backgroundColor: '#fff' },
            primary: { backgroundColor: '#ccc' },
            info: { backgroundColor: '#3cc' },
            warning: { backgroundColor: '#cc3' },
            danger: { backgroundColor: '#c33' },
            success: { backgroundColor: '#3c3' }
          }));
        
      //  const classes = useStyles();
        
        return (
            <React.Fragment>
                <NavBar/>
                <Container>
                    <Typography variant="h6" style={style}>Listing Details</Typography>
                    
                    <Table size="small" aria-label="a dense table">
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">
                                    <TextField type="text" label="Search here" fullWidth margin="normal" 
                                    name="searchString" value={this.state.searchString} onChange={this.onChange}/>
                                </TableCell>
                                <TableCell align="right">
                                    <Button variant="contained" color="primary" onClick={() => this.searchListing()}>Search</Button>
                                </TableCell>
                             </TableRow>
                         </TableBody>
                    </Table>
                                    
                    <Table size="small" aria-label="a dense table">
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">
                                        <Button variant="contained" color="primary" onClick={() => this.addUser()}>
                                        Add_User
                                    </Button>
                                </TableCell>
                                 <TableCell align="right">
                                  {/* Dynamic SimpleModal for Google Maps GOOGLE_MAPS*/}
                                        <GoogleMapsModal />
                                </TableCell>
                               {/*  <TableCell align="right">
                                         */}{/* SimpleModal for Google Maps STATIC_GOOGLE_MAPS*/}{/*
                                        <SimpleModal />
                                </TableCell> */}
                             </TableRow>
                         </TableBody>
                     </Table>
                                        
                    <Table size="small" aria-label="a dense table">
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">                                   
                               <Typography variant="body2" display="block" gutterBottom style={{color: 'black' , fontSize: '10px'}}>
                                        {this.state.users.length === 0 ? 'No records found' : +this.state.users.length === 1 ? +this.state.users.length+' record found' : +this.state.users.length+' records found'  } 
                                       
                               </Typography>
                                
                            </TableCell>                           
                         </TableRow>
                     </TableBody>
                 </Table>

                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                            {/*   <TableCell>Id</TableCell> */}
                            <TableCell align="left" lg={6} >UserDetails</TableCell>
                            {/*  <TableCell align="left">LastName</TableCell> */}
                            {/* <TableCell align="left">Address</TableCell> */}
                            {/* <TableCell align="left">AddressLine2</TableCell> 
                            <TableCell align="left">City</TableCell>
                            <TableCell align="left">ZipCode</TableCell>
                            <TableCell align="left">State</TableCell>*/}
                            {/*  <TableCell align="right">Phone</TableCell>*/}
                            {/*<TableCell align="right">TimeSpend</TableCell>*/}
                            <TableCell align="right">Comments</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>                            
                            {this.state.users                               
                                .map((row) => (
                                <TableRow key={row.id}>                                    
                                    <TableCell align="left">
                                        <Grid container>                                  
                                            <Grid item sm={12} > 
                                               <Typography variant="caption" display="block" gutterBottom style={{color: '#360392' , fontSize: '12px' , textDecorationLine: 'underline', fontWeight: 'bold' , fontStyle: 'italic'}} onClick={() => this.editUser(row.id)}>
                                                   <a> {row.id}. {row.firstName} {row.lastName} </a>
                                               </Typography>
                                               <Typography variant="caption" display="block" gutterBottom style={{color: '#008b02' , fontSize: '10px'}} >
                                                    {row.masjidName!==''  ? ' '+row.masjidName+'' : ' ' } {row.profession !== '' ? ' ('+row.profession+')' : null }
                                               </Typography>
                                               <Typography variant="body2" display="block" gutterBottom style={{fontSize: '10px'}}>
                                                   <a href={'https://maps.google.com?q='+row.addressLine1 + ', '+ row.city+ ', '+row.zipCode+ ', '+row.state}>
                                                   {row.addressLine1}, {row.addressLine2 != '' && row.addressLine2 != null ? ' '+row.addressLine2+',' : '' }  {row.city}, {row.zipCode}, {row.state} 
                                                   </a>
                                               </Typography>                                            
                                               <Typography variant="body2" display="block" gutterBottom style={{fontSize: '10px'}}>
                                                 <span style={{color: '#000080'}}><a href={row.phone1 != '' && row.phone1 != null ? 'tel:'+row.phone1 : null }>{row.phone1 != '' && row.phone1 != null ? 'ph:'+row.phone1 : null }</a> </span>
                                                 <span style={{color: '#800080'}}>{row.timeSpend != '' && row.timeSpend != null ? ' ('+row.timeSpend+')' : null } </span>
                                               </Typography>  
                                             </Grid>
                                        </Grid>
                                     </TableCell>   
                                    {/*<TableCell component="th" scope="row">
                                        {row.id}  
                                    </TableCell>*/}
                                    {/* <TableCell align="left">{row.id}. {row.firstName} {row.lastName}  
                                     ({row.profession})   
                                    [{row.addressLine1}, {row.addressLine2}, {row.city}, {row.zipCode}, {row.state}]
                                     {row.phone1}
                                    </TableCell>*/}
                                    {/* <TableCell align="left">{row.lastName}</TableCell> */}
                                     {/* <TableCell align="left">{row.addressLine1}, {row.addressLine2}, {row.city}, {row.zipCode}, {row.state}</TableCell>*/}
                                    {/*  <TableCell align="left">{row.addressLine2}</TableCell>
                                    <TableCell align="left">{row.city}</TableCell>
                                    <TableCell align="left">{row.zipCode}</TableCell>
                                    <TableCell align="left">{row.state}</TableCell> */}
                                    {/*<TableCell align="right">{row.phone1}</TableCell>*/}
                                    {/*<TableCell align="left">{row.timeSpend}</TableCell>*/}
                                    <TableCell align="right"> 
                                        <Grid container>                                        
                                            <Grid item  sm={12}>                                     
                                                <Typography variant="caption" display="block" gutterBottom style={{color: 'black' , fontSize: '12px'}}>  {row.comments} </Typography> 
                                                <Typography variant="caption" display="block" gutterBottom style={{color: 'black' , fontSize: '12px'}}>  {row.dateLastMet}</Typography>
                                            </Grid>                                            
                                        </Grid>
                                    </TableCell>
                                       
                                    {/*<TableCell align="right" onClick={() => this.editUser(row.id)}>
                                    <CreateIcon /> 
                                    <Button variant="contained" color="primary" onClick={() => this.editUser(row.id)}>
                                        Edit
                                    </Button>
                                    
                                    </TableCell>*/}
                                    {/*<TableCell align="right" onClick={() => this.deleteUser(row.id)}><DeleteIcon /></TableCell>*/}
                                    
                                </TableRow>
                            ))}
                        </TableBody>
                          <TableFooter>
                            
                       </TableFooter>    
                    </Table>
                </Container>
            </React.Fragment>
        );
    }

}

const style ={
    display: 'flex',
    justifyContent: 'center'
}



export default ListUserComponent;