import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top =12;
    const left = 2;
    const right = 2;
    const bottom = 1;
    return {
        top: `${top}%`,
        left: `${left}%`,
        right: `${right}%`,
        bottom: `${bottom}%`,
        transform: `translate(-${top}%, -${left}%, -${right}%, -${bottom}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 1014,
        height: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 1, 1, 1),
    },
}));

export default function SimpleModal() {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleOpen}>
            Static_Google_Map
            </Button>

            <Modal fullScreen 
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
            >
            {/* A JSX comment */}
            
            <div style={{ width: '99%', height: '99%', position: 'absolute', left: '5px', right: '20px', top: '60px', overflow: 'hidden'}}>
            	<iframe src="https://www.google.com/maps/d/embed?mid=1sF6u_fizibuX0Rkv7RakiCTKZgLiP1LG" style={{width:'100%',height:'100%'}} allowFullScreen/>                     
            </div>
             
            {/*      <div style={modalStyle} className={classes.paper}>
                    <iframe src="https://www.google.com/maps/d/embed?mid=1sF6u_fizibuX0Rkv7RakiCTKZgLiP1LG" style={{width:'100%',height:'100%'}} allowFullScreen/>                     
                </div>
           
           
            <div style={{width:'99%',height:'80%',  top: '200px'}}>
            <iframe src="https://www.google.com/maps/d/embed?mid=1sF6u_fizibuX0Rkv7RakiCTKZgLiP1LG" style={{width:'100%',height:'100%'}}/>                     
            </div>
             */}
            </Modal>
        </div>
    );
}