import React, { createContext,useContext, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";

const markers2 = [
  {
    id: 1,
    name: "Chicago, Illinois",
    position: { "lat": 41.881832, "lng": -87.623177 }
  },
  {
    id: 2,
    name: "Denver, Colorado",
    position: { lat: 39.739235, lng: -104.99025 }
  },
  {
    id: 3,
    name: "Los Angeles, California",
    position: { lat: 34.052235, lng: -118.243683 }
  },
  {
    id: 4,
    name: "New York, New York",
    position: { lat: 40.712776, lng: -74.005974 }
  },
 {
   id: 5,
   name: "1314 Wickstead Rd, Ballwin, MO. 63011",
   position: { "lat": 38.602482635941, "lng": -90.49472883686299 }
 },
 {
    id: 6,
    name: "Dallas, Texas",
    position: { lat: 32.7767, lng: -96.7970 }
  }
];
function Map() {
  const [activeMarker, setActiveMarker] = useState(null);
    let userListDetails = JSON.parse(localStorage.getItem("markers"));
    console.log('userListDetails : ' +userListDetails);
    console.log('userListDetails stringify: ' +JSON.stringify(localStorage.getItem('markers')));
    let markers = [];

    userListDetails.map(user => {
            if(user.lat !== null && user.lng !== null ){
              let position = {lat:user.lat, lng: user.lng};
              let marker = {};
              marker.id = user.id;
              marker.name = user.firstName + ' ' + user.lastName;
              marker.position = {lat:user.lat, lng: user.lng};

              let tempAddress = '';
              tempAddress += user.addressLine1;
              if(user.addressLine2 !== '' && user.addressLine2 !== null ){
              tempAddress += ', '+user.addressLine2;
              }
              tempAddress += ', '+user.city;
              tempAddress += ', '+user.state;
              tempAddress += ' - '+user.zipCode;

              marker.address = tempAddress;
              marker.direction = user.addressLine1 + ', ' + user.city+ ', ' + user.zipCode+ ', ' + user.state;
              marker.phone1 = user.phone1;
              marker.timeSpend = user.timeSpend;
              marker.comments = user.comments;
              marker.dateLastMet = user.dateLastMet;
              markers.push(marker);
           }
       });
    console.log('selectedMarkers stringify: ' +JSON.stringify(markers));

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.map(marker => bounds.extend(marker.position));
    map.fitBounds(bounds);
  };

  return (
        <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "100vw", height: "100vh" }}
    >

      {markers.map(({ id, name, position, address, direction, phone1, timeSpend, comments, dateLastMet }) => (
        <Marker
          key={id}
          position={position}
          label={name}
          title={name}
          icon={'http://maps.google.com/mapfiles/kml/paddle/grn-blank.png'}
          onClick={() => handleActiveMarker(id)}
          name={name}
        >
        {/* default : dont specify icon property/}
         {/* icon={'http://maps.google.com/mapfiles/kml/paddle/blu-blank.png'}*/}

          {activeMarker === id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>

           {/*  <div>
                 <h3>{selectedCenter.name}</h3>
                 <h5>{selectedCenter.address}, {selectedCenter.city},{selectedCenter.state} {selectedCenter.zip_code}</h5>
                 <h5>{selectedCenter.phone_number}</h5>
                 <p>Hours of operation: {selectedCenter.hours}</p>
              </div>*/}

              <div>
                  <h3>{id}. {name}</h3>
                  <h5>{address}</h5>
                  <h5>{phone1}</h5>
                  <a href={'https://maps.google.com?q='+direction}>
                        <p>Direction</p>
                  </a>
                  <a href={'tel:'+phone1}>
                      <p>Call</p>
                  </a>
                    <h5>timeSpend: {timeSpend}</h5>
                  <h5>dateLastMet: {dateLastMet}</h5>
                  <h5>comments: {comments}</h5>
              </div>

            </InfoWindow>

          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  );
}

export default Map;
